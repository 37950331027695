<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="feUser_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-feUser-filter></app-feUser-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="feUsers"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import FeUserFilter from './FeUserFilter'
import DataTable from '../shared/DataTable'
import ButtonCreate from '../shared/ButtonCreate'

export default {
  name: 'FeUserList',
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('feUser.id'),
          username: this.$t('feUser.username'),
          email: this.$t('feUser.email'),
          enabled: this.$t('feUser.enabled'),
          lastLogin: this.$t('feUser.last_login'),
          createdAt: this.$t('feUser.created_at'),
          modifiedAt: this.$t('feUser.modified_at')
        },
        actions: {
          edit: 'feUser_edit'
        },
        render: {
          enabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    feUsers () {
      return this.$store.getters['feUser/list']
    },
    totalCount () {
      return this.$store.getters['feUser/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['feUser/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFeUserFilter: FeUserFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getFeUsers () {
      this.$store.dispatch('feUser/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('feUser/setPage', page)
      this.getFeUsers()
    }
  },
  mounted () {
    this.page = this.$store.getters['feUser/page']
    this.getFeUsers()
  }
}
</script>
